<template>
  <div class="production">

    <div class="head-links">
      <router-link class="back-links" to="/pediatrics">Головна</router-link>
      <span>/</span>
      <router-link class="back-links" to="/calculator">Калькулятор</router-link>
      <span>/</span>
      <span class="back-links now-page">{{ currentProd.name }}</span>
    </div>

    <div class="content-wrapper">

      <div class="content">

        <div class="production-block">

          <div class="production-item">
            <p class="name" v-html="currentProd.name"></p>
            <p class="type" v-html="currentProd.filters.name"></p>

            <div class="single-product__slider-container">
              <img v-if="currentProd.img.length === 1" v-else :src="require(`../assets/img/pack/${currentProd.img[0]}`)"
                   alt="">

              <swiper v-if="currentProd.img.length >= 2" class="swiper" :options="swiperOptions">

                <swiper-slide
                    v-for="(key, value) in currentProd.img"
                    :key="value">
                  <img :src="require(`../assets/img/pack/${key}`)" alt="">
                </swiper-slide>

                <div class="single-product__nav swiper-button-prev" slot="button-prev"></div>
                <div class="single-product__nav swiper-button-next" slot="button-next"></div>

              </swiper>


              <!--              <div class="single-product__slider-container">-->
              <!--                <div-->
              <!--                    class="single-product__nav prev"-->
              <!--                    @click="moveSlider(-1)"-->
              <!--                    :class="{disabled: atHeadOfList}"-->
              <!--                    v-if="currentProd.img.length > 1"-->
              <!--                ></div>-->
              <!--                <div class="single-product__slider">-->
              <!--                  <div-->
              <!--                      class="single-product__images"-->
              <!--                      style="{transform: translateX(0)}"-->
              <!--                  >-->
              <!--                    <div ref="slider"-->
              <!--                         class="single-product__image"-->
              <!--                         v-for="img of currentProd.img"-->
              <!--                         :key="img"-->
              <!--                    >-->
              <!--                      <img-->
              <!--                          :src="-->
              <!--                      require(`../assets/img/pack/${img}`)-->
              <!--                    "-->
              <!--                          alt-->
              <!--                      />-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div-->
              <!--                    class="single-product__nav next"-->
              <!--                    @click="moveSlider(1)"-->
              <!--                    :class="{disabled: atEndOfList}"-->
              <!--                    v-if="currentProd.img.length > 1"-->
              <!--                ></div>-->
              <!--              </div>-->
            </div>
          </div>

          <div class="filter-items" v-bind:class="[{ active:numberProduction.toString() === '18' }]">
            <single-select @selectedDataChange="selectedMaleChange" :defaultName="'Стать'" :selectedData="selectedMale"
                           :data="males"/>

            <column-item @selectedDataChange="selectedAgeChange" :value="selectedAge" :defaultName="'Вік'"
                         :minAge="currentProd.minAge" :maxAge="currentProd.maxAge" :step="0.5"/>

            <column-item @selectedDataChange="selectedWeightChange" :value="selectedWeight" :age="selectedAge"
                         :minWeight="currentProd.minWeight" :maxWeight="currentProd.maxWeight" :defaultName="'Вага'"
                         :step="0.1"/>

            <multiple-select @selectedDataChange="selectedStatesChange" :defaultName="'Стан'"
                             :selectedData="selectedStates" :data="states"/>

            <single-select @selectedDataChange="selectedActivityChange" :defaultName="'Активність'"
                           :selectedData="selectedActivity" :data="activities"/>

            <single-select @selectedDataChange="selectedGrowthRateChange" :defaultName="'Швидкість зростання'"
                           :selectedData="selectedGrowthRate" :data="growthRates" v-if="selectedAge >= 3"/>
          </div>

          <div v-bind:class="[{ active:numberProduction.toString() === '18' }]" class="calc-day-norm">
            <button @click="calc()" v-if="isCalculated">розрахувати знову</button>
            <button @click="calc()" v-else
                    :disabled="!selectedMale || !selectedAge || !selectedWeight || !selectedStates || !selectedActivity">
              розрахувати
            </button>
            <router-link
                to="/pediatrics/sampling"
                class="sampling-btn"
                v-if="!(['6', '7', '5', '8', '9', '10', '16'].includes(currentProd.id))"
            >
              <p>Замовити зразки</p>
            </router-link>
          </div>

          <div ref="printMe" class="day-norm" v-if="isCalculated && currentProd.name !== 'Суміш суха Nutrison Powder'">
            <div class="kkal-norm">
              <div class="title">
                Добова норма калорій:
              </div>
              <div class="kkal-container">
                <div class="item">
                  <div class="icon">
                    <svg width="23" height="38" viewBox="0 0 23 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="38"
                            fill="black">
                        <rect fill="white" width="23" height="38"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M1 20.4464L7.10714 1H20.9286L15.1429 12.7321H22.375L5.82143 37L9.19643 20.4464H1ZM8.03192 2.11701H18.9691L13.3376 13.8961H20.0141L7.80345 32.1994L10.6974 19.4303H2.47239L8.03192 2.11701Z"/>
                      </mask>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1 20.4464L7.10714 1H20.9286L15.1429 12.7321H22.375L5.82143 37L9.19643 20.4464H1ZM8.03192 2.11701H18.9691L13.3376 13.8961H20.0141L7.80345 32.1994L10.6974 19.4303H2.47239L8.03192 2.11701Z"
                            fill="#1E3B89"/>
                      <path
                          d="M7.10714 1V0.8H6.96032L6.91633 0.940076L7.10714 1ZM1 20.4464L0.809188 20.3865L0.727559 20.6464H1V20.4464ZM20.9286 1L21.1079 1.08846L21.2502 0.8H20.9286V1ZM15.1429 12.7321L14.9635 12.6437L14.8212 12.9321H15.1429V12.7321ZM22.375 12.7321L22.5402 12.8448L22.7535 12.5321H22.375V12.7321ZM5.82143 37L5.62546 36.96L5.98665 37.1127L5.82143 37ZM9.19643 20.4464L9.3924 20.4864L9.44132 20.2464H9.19643V20.4464ZM18.9691 2.11701L19.1496 2.20328L19.2864 1.91701H18.9691V2.11701ZM8.03192 2.11701V1.91701H7.88609L7.8415 2.05586L8.03192 2.11701ZM13.3376 13.8961L13.1572 13.8098L13.0203 14.0961H13.3376V13.8961ZM20.0141 13.8961L20.1805 14.0071L20.388 13.6961H20.0141V13.8961ZM7.80345 32.1994L7.60839 32.1552L7.96982 32.3104L7.80345 32.1994ZM10.6974 19.4303L10.8925 19.4745L10.9479 19.2303H10.6974V19.4303ZM2.47239 19.4303L2.28197 19.3691L2.19811 19.6303H2.47239V19.4303ZM6.91633 0.940076L0.809188 20.3865L1.19081 20.5064L7.29796 1.05992L6.91633 0.940076ZM20.9286 0.8H7.10714V1.2H20.9286V0.8ZM15.3222 12.8206L21.1079 1.08846L20.7492 0.911542L14.9635 12.6437L15.3222 12.8206ZM22.375 12.5321H15.1429V12.9321H22.375V12.5321ZM5.98665 37.1127L22.5402 12.8448L22.2098 12.6194L5.65621 36.8873L5.98665 37.1127ZM9.00046 20.4065L5.62546 36.96L6.0174 37.04L9.3924 20.4864L9.00046 20.4065ZM1 20.6464H9.19643V20.2464H1V20.6464ZM18.9691 1.91701H8.03192V2.31701H18.9691V1.91701ZM13.518 13.9824L19.1496 2.20328L18.7887 2.03074L13.1572 13.8098L13.518 13.9824ZM20.0141 13.6961H13.3376V14.0961H20.0141V13.6961ZM7.96982 32.3104L20.1805 14.0071L19.8477 13.7851L7.63707 32.0884L7.96982 32.3104ZM10.5024 19.386L7.60839 32.1552L7.9985 32.2436L10.8925 19.4745L10.5024 19.386ZM2.47239 19.6303H10.6974V19.2303H2.47239V19.6303ZM7.8415 2.05586L2.28197 19.3691L2.66281 19.4914L8.22234 2.17816L7.8415 2.05586Z"
                          fill="#1E3B89" mask="url(#path-1-outside-1)"/>
                      <path
                          d="M8.31175 7.7377C8.06652 7.77634 7.86527 7.95237 7.79434 8.19027L5.5682 15.6565C5.43269 16.111 5.81779 16.5513 6.28626 16.4775C6.53149 16.4388 6.73274 16.2628 6.80368 16.0249L9.02981 8.55864C9.16532 8.10416 8.78021 7.66388 8.31175 7.7377Z"
                          fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                    </svg>
                  </div>
                  <div class="value">
                    <div class="number">
                      {{ dayNormKkal }}
                    </div>
                    <div class="text">
                      ккал
                    </div>
                  </div>
                </div>
                або
                <div class="item">
                  <div class="icon">
                    <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M1 1L5.46794 29.4485C5.77396 31.5946 7.61885 33.1911 9.79598 33.1911H19.4488C21.6347 33.1911 23.4883 31.5772 23.7769 29.4136L28 1H1V1Z"
                          stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"/>
                      <path d="M6.52588 15.7872H22.2642" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                            stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="value">
                    <div class="number">
                      {{ dayNormMixture }} <small>мл</small>
                    </div>
                    <div class="text">
                      суміші
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kkal-norm" v-if="currentProd.protein > 0">
              <div class="title">
                Добова норма білка:
              </div>
              <div class="kkal-container">
                <div class="item">
                  <div class="icon">
                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M21.2299 16.2151H13.1716C12.8503 16.2151 12.5558 16.0269 12.422 15.7042L8.83458 6.69579C8.67395 6.29243 8.86135 5.8084 9.2897 5.64706C9.69128 5.48571 10.1732 5.67395 10.3338 6.1042L13.7338 14.6286H20.7212L24.0944 6.07731C24.2551 5.67395 24.7369 5.45882 25.1385 5.62016C25.5401 5.78151 25.7543 6.26554 25.5936 6.6689L21.9795 15.7042C21.8724 16.0269 21.5779 16.2151 21.2299 16.2151Z"
                          fill="#1E3B89"/>
                      <path
                          d="M16.4913 12.4504C16.17 12.4504 15.8487 12.2622 15.7416 11.9126L13.9747 7.09913C13.8141 6.66888 14.0283 6.21174 14.4566 6.0504C14.885 5.88905 15.3401 6.10418 15.5007 6.53443L17.2676 11.3479C17.4283 11.7781 17.2141 12.2353 16.7857 12.3966C16.6787 12.4235 16.5983 12.4504 16.4913 12.4504Z"
                          fill="#1E3B89"/>
                      <path
                          d="M18.2046 12.6925C18.2046 13.3648 17.6692 13.9026 16.9999 13.9026C16.3306 13.9026 15.7952 13.3648 15.7952 12.6925C15.7952 12.0202 16.3306 11.4824 16.9999 11.4824C17.6692 11.4824 18.2046 12.0202 18.2046 12.6925Z"
                          fill="#1E3B89"/>
                      <path
                          d="M9.4772 6.83027C9.26302 6.83027 9.04885 6.74959 8.88822 6.56136C8.59373 6.23867 8.6205 5.72775 8.94176 5.43195C10.9496 3.57648 13.9748 2.50085 17.2142 2.50085C20.4803 2.50085 23.4788 3.57648 25.4866 5.43195C25.8079 5.72775 25.8347 6.23867 25.5402 6.56136C25.2457 6.88405 24.737 6.91094 24.4158 6.61514C22.7292 5.05548 20.052 4.1143 17.2142 4.1143C14.4032 4.1143 11.726 5.05548 10.0126 6.61514C9.852 6.77648 9.6646 6.83027 9.4772 6.83027Z"
                          fill="#1E3B89"/>
                      <path
                          d="M16.9999 31.4621C16.5448 31.4621 16.1968 31.1126 16.1968 30.6554V24.4705C16.1968 24.0134 16.5448 23.6638 16.9999 23.6638C17.455 23.6638 17.8031 24.0134 17.8031 24.4705V30.6554C17.8031 31.1126 17.455 31.4621 16.9999 31.4621Z"
                          fill="#1E3B89"/>
                      <path
                          d="M24.8976 32H9.10236C6.9874 32 4.87244 30.3059 4.39055 28.2084L0.214173 9.49244C0.187402 9.41177 0.187402 9.35798 0.187402 9.27731C0.080315 8.71261 0 8.12101 0 7.52941C0 3.38824 3.37323 0 7.49606 0H26.5039C30.6268 0 34 3.38824 34 7.52941C34 8.28235 33.8929 9.0084 33.6787 9.73445C33.6787 9.81513 33.6787 9.92269 33.6787 10.0034L29.6094 28.2084C29.1276 30.3059 27.0126 32 24.8976 32ZM1.84724 9.38488L5.97008 27.8588C6.26457 29.2303 7.71024 30.3866 9.10236 30.3866H24.8976C26.2898 30.3866 27.7354 29.2303 28.0299 27.8588L32.0189 10.0034C31.9921 9.84202 31.9921 9.68067 32.0457 9.54622C32.2866 8.90084 32.3937 8.22857 32.3937 7.52941C32.3937 4.27563 29.7433 1.61345 26.5039 1.61345H7.49606C4.25669 1.61345 1.6063 4.27563 1.6063 7.52941C1.6063 8.06723 1.68661 8.57815 1.82047 9.08908C1.84724 9.19664 1.84724 9.27731 1.84724 9.38488Z"
                          fill="#1E3B89"/>
                    </svg>
                  </div>
                  <div class="value">
                    <div class="number">
                      {{ replaceDotToComa(dayNormOfProteinGrams) }}
                    </div>
                    <div class="text">
                      грам
                    </div>
                  </div>
                </div>
                або
                <div class="item">
                  <div class="icon">
                    <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M1 1L5.46794 29.4485C5.77396 31.5946 7.61885 33.1911 9.79598 33.1911H19.4488C21.6347 33.1911 23.4883 31.5772 23.7769 29.4136L28 1H1V1Z"
                          stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"/>
                      <path d="M6.52588 15.7872H22.2642" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                            stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="value">
                    <div class="number">
                      {{ dayNormProteinMixture }} <small>мл</small>
                    </div>
                    <div class="text">
                      суміші
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div ref="printMe" class="day-norm-powder"
               v-if="isCalculated && currentProd.name === 'Суміш суха Nutrison Powder'">
            <div class="block">
              <div class="item">
                <div class="title">
                  Добова норма калорій:
                </div>
                <div class="item-container">
                  <div class="icon">
                    <svg width="23" height="38" viewBox="0 0 23 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="38"
                            fill="black">
                        <rect fill="white" width="23" height="38"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M1 20.4464L7.10714 1H20.9286L15.1429 12.7321H22.375L5.82143 37L9.19643 20.4464H1ZM8.03192 2.11701H18.9691L13.3376 13.8961H20.0141L7.80345 32.1994L10.6974 19.4303H2.47239L8.03192 2.11701Z"/>
                      </mask>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1 20.4464L7.10714 1H20.9286L15.1429 12.7321H22.375L5.82143 37L9.19643 20.4464H1ZM8.03192 2.11701H18.9691L13.3376 13.8961H20.0141L7.80345 32.1994L10.6974 19.4303H2.47239L8.03192 2.11701Z"
                            fill="#1E3B89"/>
                      <path
                          d="M7.10714 1V0.8H6.96032L6.91633 0.940076L7.10714 1ZM1 20.4464L0.809188 20.3865L0.727559 20.6464H1V20.4464ZM20.9286 1L21.1079 1.08846L21.2502 0.8H20.9286V1ZM15.1429 12.7321L14.9635 12.6437L14.8212 12.9321H15.1429V12.7321ZM22.375 12.7321L22.5402 12.8448L22.7535 12.5321H22.375V12.7321ZM5.82143 37L5.62546 36.96L5.98665 37.1127L5.82143 37ZM9.19643 20.4464L9.3924 20.4864L9.44132 20.2464H9.19643V20.4464ZM18.9691 2.11701L19.1496 2.20328L19.2864 1.91701H18.9691V2.11701ZM8.03192 2.11701V1.91701H7.88609L7.8415 2.05586L8.03192 2.11701ZM13.3376 13.8961L13.1572 13.8098L13.0203 14.0961H13.3376V13.8961ZM20.0141 13.8961L20.1805 14.0071L20.388 13.6961H20.0141V13.8961ZM7.80345 32.1994L7.60839 32.1552L7.96982 32.3104L7.80345 32.1994ZM10.6974 19.4303L10.8925 19.4745L10.9479 19.2303H10.6974V19.4303ZM2.47239 19.4303L2.28197 19.3691L2.19811 19.6303H2.47239V19.4303ZM6.91633 0.940076L0.809188 20.3865L1.19081 20.5064L7.29796 1.05992L6.91633 0.940076ZM20.9286 0.8H7.10714V1.2H20.9286V0.8ZM15.3222 12.8206L21.1079 1.08846L20.7492 0.911542L14.9635 12.6437L15.3222 12.8206ZM22.375 12.5321H15.1429V12.9321H22.375V12.5321ZM5.98665 37.1127L22.5402 12.8448L22.2098 12.6194L5.65621 36.8873L5.98665 37.1127ZM9.00046 20.4065L5.62546 36.96L6.0174 37.04L9.3924 20.4864L9.00046 20.4065ZM1 20.6464H9.19643V20.2464H1V20.6464ZM18.9691 1.91701H8.03192V2.31701H18.9691V1.91701ZM13.518 13.9824L19.1496 2.20328L18.7887 2.03074L13.1572 13.8098L13.518 13.9824ZM20.0141 13.6961H13.3376V14.0961H20.0141V13.6961ZM7.96982 32.3104L20.1805 14.0071L19.8477 13.7851L7.63707 32.0884L7.96982 32.3104ZM10.5024 19.386L7.60839 32.1552L7.9985 32.2436L10.8925 19.4745L10.5024 19.386ZM2.47239 19.6303H10.6974V19.2303H2.47239V19.6303ZM7.8415 2.05586L2.28197 19.3691L2.66281 19.4914L8.22234 2.17816L7.8415 2.05586Z"
                          fill="#1E3B89" mask="url(#path-1-outside-1)"/>
                      <path
                          d="M8.31175 7.7377C8.06652 7.77634 7.86527 7.95237 7.79434 8.19027L5.5682 15.6565C5.43269 16.111 5.81779 16.5513 6.28626 16.4775C6.53149 16.4388 6.73274 16.2628 6.80368 16.0249L9.02981 8.55864C9.16532 8.10416 8.78021 7.66388 8.31175 7.7377Z"
                          fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
                    </svg>
                  </div>
                  <div class="value">
                    <div class="number">
                      {{ dayNormKkal }}
                    </div>
                    <div class="name">
                      ккал
                    </div>
                  </div>
                </div>
              </div>
              <div class="item" v-if="currentProd.protein > 0">
                <div class="title">
                  Добова норма білка:
                </div>
                <div class="item-container">
                  <div class="icon">
                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M21.2299 16.2151H13.1716C12.8503 16.2151 12.5558 16.0269 12.422 15.7042L8.83458 6.69579C8.67395 6.29243 8.86135 5.8084 9.2897 5.64706C9.69128 5.48571 10.1732 5.67395 10.3338 6.1042L13.7338 14.6286H20.7212L24.0944 6.07731C24.2551 5.67395 24.7369 5.45882 25.1385 5.62016C25.5401 5.78151 25.7543 6.26554 25.5936 6.6689L21.9795 15.7042C21.8724 16.0269 21.5779 16.2151 21.2299 16.2151Z"
                          fill="#1E3B89"/>
                      <path
                          d="M16.4913 12.4504C16.17 12.4504 15.8487 12.2622 15.7416 11.9126L13.9747 7.09913C13.8141 6.66888 14.0283 6.21174 14.4566 6.0504C14.885 5.88905 15.3401 6.10418 15.5007 6.53443L17.2676 11.3479C17.4283 11.7781 17.2141 12.2353 16.7857 12.3966C16.6787 12.4235 16.5983 12.4504 16.4913 12.4504Z"
                          fill="#1E3B89"/>
                      <path
                          d="M18.2046 12.6925C18.2046 13.3648 17.6692 13.9026 16.9999 13.9026C16.3306 13.9026 15.7952 13.3648 15.7952 12.6925C15.7952 12.0202 16.3306 11.4824 16.9999 11.4824C17.6692 11.4824 18.2046 12.0202 18.2046 12.6925Z"
                          fill="#1E3B89"/>
                      <path
                          d="M9.4772 6.83027C9.26302 6.83027 9.04885 6.74959 8.88822 6.56136C8.59373 6.23867 8.6205 5.72775 8.94176 5.43195C10.9496 3.57648 13.9748 2.50085 17.2142 2.50085C20.4803 2.50085 23.4788 3.57648 25.4866 5.43195C25.8079 5.72775 25.8347 6.23867 25.5402 6.56136C25.2457 6.88405 24.737 6.91094 24.4158 6.61514C22.7292 5.05548 20.052 4.1143 17.2142 4.1143C14.4032 4.1143 11.726 5.05548 10.0126 6.61514C9.852 6.77648 9.6646 6.83027 9.4772 6.83027Z"
                          fill="#1E3B89"/>
                      <path
                          d="M16.9999 31.4621C16.5448 31.4621 16.1968 31.1126 16.1968 30.6554V24.4705C16.1968 24.0134 16.5448 23.6638 16.9999 23.6638C17.455 23.6638 17.8031 24.0134 17.8031 24.4705V30.6554C17.8031 31.1126 17.455 31.4621 16.9999 31.4621Z"
                          fill="#1E3B89"/>
                      <path
                          d="M24.8976 32H9.10236C6.9874 32 4.87244 30.3059 4.39055 28.2084L0.214173 9.49244C0.187402 9.41177 0.187402 9.35798 0.187402 9.27731C0.080315 8.71261 0 8.12101 0 7.52941C0 3.38824 3.37323 0 7.49606 0H26.5039C30.6268 0 34 3.38824 34 7.52941C34 8.28235 33.8929 9.0084 33.6787 9.73445C33.6787 9.81513 33.6787 9.92269 33.6787 10.0034L29.6094 28.2084C29.1276 30.3059 27.0126 32 24.8976 32ZM1.84724 9.38488L5.97008 27.8588C6.26457 29.2303 7.71024 30.3866 9.10236 30.3866H24.8976C26.2898 30.3866 27.7354 29.2303 28.0299 27.8588L32.0189 10.0034C31.9921 9.84202 31.9921 9.68067 32.0457 9.54622C32.2866 8.90084 32.3937 8.22857 32.3937 7.52941C32.3937 4.27563 29.7433 1.61345 26.5039 1.61345H7.49606C4.25669 1.61345 1.6063 4.27563 1.6063 7.52941C1.6063 8.06723 1.68661 8.57815 1.82047 9.08908C1.84724 9.19664 1.84724 9.27731 1.84724 9.38488Z"
                          fill="#1E3B89"/>
                    </svg>
                  </div>
                  <div class="value">
                    <div class="number">
                      {{ replaceDotToComa(dayNormOfProteinGrams) }}
                    </div>
                    <div class="name">
                      грам
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block col">
              <div class="title">
                <b>Ізокалорійне розведення</b> <br>(1 ккал/мл)
              </div>
              <div class="items">
                <div class="item">
                  <div class="item-container">
                    <div class="icon">
                      <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 1L5.46794 29.4485C5.77396 31.5946 7.61885 33.1911 9.79598 33.1911H19.4488C21.6347 33.1911 23.4883 31.5772 23.7769 29.4136L28 1H1V1Z"
                            stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M6.52588 15.7872H22.2642" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="value">
                      <div class="number">
                        {{ dayNormKkal }} <small>мл</small>
                      </div>
                      <div class="name">
                        суміші
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-container">
                    <div class="icon">
                      <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 1L5.46794 29.4485C5.77396 31.5946 7.61885 33.1911 9.79598 33.1911H19.4488C21.6347 33.1911 23.4883 31.5772 23.7769 29.4136L28 1H1V1Z"
                            stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M6.52588 15.7872H22.2642" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="value">
                      <div class="number">
                        {{ dayNormProteinMixture }} <small>мл</small>
                      </div>
                      <div class="name">
                        суміші
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-container">
                    <div class="icon">
                      <svg width="33" height="50" viewBox="0 0 33 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.07007 16.4652L8.55252 45.0061C8.85954 47.1592 10.7104 48.7608 12.8946 48.7608H22.5788C24.7718 48.7608 26.6315 47.1417 26.9209 44.9711L31.1578 16.4652H4.07007V16.4652Z"
                            stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M9.61401 31.3005H25.4035" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 1.00024L2.31579 9.02602H15.4561L17.1403 1.00024H1Z" stroke="#1E3B89"
                              stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17.1404 1H32" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                    </div>
                    <div class="value">
                      <div class="number">
                        {{ getSpoonCount(dayNormKkal) }} <br>
                        <small>{{ declOfNum(getSpoonCount(dayNormKkal), ['ложка', 'ложки', 'ложок']) }}</small>
                      </div>
                      <div class="name">
                        суміші
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-container">
                    <div class="icon">
                      <svg width="33" height="50" viewBox="0 0 33 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.07007 16.4652L8.55252 45.0061C8.85954 47.1592 10.7104 48.7608 12.8946 48.7608H22.5788C24.7718 48.7608 26.6315 47.1417 26.9209 44.9711L31.1578 16.4652H4.07007V16.4652Z"
                            stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M9.61401 31.3005H25.4035" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 1.00024L2.31579 9.02602H15.4561L17.1403 1.00024H1Z" stroke="#1E3B89"
                              stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17.1404 1H32" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="value">
                      <div class="number">
                        {{ getSpoonCount(dayNormProteinMixture) }} <br>
                        <small>{{ declOfNum(getSpoonCount(dayNormKkal), ['ложка', 'ложки', 'ложок']) }}</small>
                      </div>
                      <div class="name">
                        суміші
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item water">
                  <b>+ {{ getWaterCount(dayNormKkal) }} мл</b> води
                </div>
                <div class="item water">
                  <b>+ {{ getWaterCount(dayNormProteinMixture) }} мл</b> води
                </div>
              </div>
            </div>
            <div class="block col">
              <div class="title">
                <b>Гіперкалорійне розведення</b> <br>(1,5 ккал/мл)
              </div>
              <div class="items">
                <div class="item">
                  <div class="item-container">
                    <div class="icon">
                      <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 1L5.46794 29.4485C5.77396 31.5946 7.61885 33.1911 9.79598 33.1911H19.4488C21.6347 33.1911 23.4883 31.5772 23.7769 29.4136L28 1H1V1Z"
                            stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M6.52588 15.7872H22.2642" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="value">
                      <div class="number">
                        {{ Math.round(dayNormKkal / 1.5) }} <small>мл</small>
                      </div>
                      <div class="name">
                        суміші
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-container">
                    <div class="icon">
                      <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 1L5.46794 29.4485C5.77396 31.5946 7.61885 33.1911 9.79598 33.1911H19.4488C21.6347 33.1911 23.4883 31.5772 23.7769 29.4136L28 1H1V1Z"
                            stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M6.52588 15.7872H22.2642" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="value">
                      <div class="number">
                        {{ getGipoMl(dayNormOfProteinGrams) }} <small>мл</small>
                      </div>
                      <div class="name">
                        суміші
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-container">
                    <div class="icon">
                      <svg width="33" height="50" viewBox="0 0 33 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.07007 16.4652L8.55252 45.0061C8.85954 47.1592 10.7104 48.7608 12.8946 48.7608H22.5788C24.7718 48.7608 26.6315 47.1417 26.9209 44.9711L31.1578 16.4652H4.07007V16.4652Z"
                            stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M9.61401 31.3005H25.4035" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 1.00024L2.31579 9.02602H15.4561L17.1403 1.00024H1Z" stroke="#1E3B89"
                              stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17.1404 1H32" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                    </div>
                    <div class="value">
                      <div class="number">
                        {{ getSpoonCount(dayNormKkal) }} <br>
                        <small>{{ declOfNum(getSpoonCount(dayNormKkal), ['ложка', 'ложки', 'ложок']) }}</small>
                      </div>
                      <div class="name">
                        суміші
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-container">
                    <div class="icon">
                      <svg width="33" height="50" viewBox="0 0 33 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.07007 16.4652L8.55252 45.0061C8.85954 47.1592 10.7104 48.7608 12.8946 48.7608H22.5788C24.7718 48.7608 26.6315 47.1417 26.9209 44.9711L31.1578 16.4652H4.07007V16.4652Z"
                            stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M9.61401 31.3005H25.4035" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 1.00024L2.31579 9.02602H15.4561L17.1403 1.00024H1Z" stroke="#1E3B89"
                              stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17.1404 1H32" stroke="#1E3B89" stroke-width="1.5" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="value">
                      <div class="number">
                        {{ getSpoonCount(dayNormProteinMixture) }} <br>
                        <small>{{ declOfNum(getSpoonCount(dayNormKkal), ['ложка', 'ложки', 'ложок']) }}</small>
                      </div>
                      <div class="name">
                        суміші
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item water">
                  <b>+ {{ getWaterCountGipo(Math.round(dayNormKkal / 1.5)) }} мл</b> води
                </div>
                <div class="item water">
                  <b>+ {{ getWaterCountGipo(getGipoMl(dayNormOfProteinGrams)) }} мл</b> води
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="share-btn" v-if="isCalculated">
          <div class="download btn" @click="saveImg()">
            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12.4436H12" stroke="#00ACE6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"
                    stroke-linejoin="round"/>
              <path d="M6.46484 8.08519V1" stroke="#00ACE6" stroke-width="1.5" stroke-miterlimit="10"
                    stroke-linecap="square" stroke-linejoin="round"/>
              <path d="M9.68072 5.66956L6.46463 8.85293L3.24854 5.66956" stroke="#00ACE6" stroke-width="1.5"
                    stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            Завантажити
          </div>

          <a id="viber_share" style="display: none;" href=""></a>
          <div @click="sendToServerScreen()" class="viber btn">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M17.0315 9.02942L17.0622 8.99573L17.0315 8.96204L9.7397 0.966308L9.65276 0.87097V1V5.73955H8.85571C6.74419 5.73955 4.75869 6.56196 3.26555 8.0551C1.77241 9.54824 0.95 11.5337 0.95 13.6454V17V17.129L1.03693 17.0337L2.43635 15.5004C4.28757 13.4722 6.91073 12.2937 9.65276 12.253V16.9916V17.1206L9.7397 17.0253L17.0315 9.02942ZM15.658 8.99573L10.6903 14.443V11.2644V11.2144H10.6403H9.79907C6.86704 11.2144 4.04602 12.3946 1.98762 14.4673V13.6454C1.98762 11.8107 2.70196 10.086 3.99922 8.78877C5.29649 7.49151 7.02107 6.77717 8.85571 6.77717H10.6403H10.6903V6.72717V3.54858L15.658 8.99573Z"
                  fill="#00ACE6" stroke="#00ACE6" stroke-width="0.1"/>
            </svg>
            Надіслати
          </div>
        </div>

        <div class="drop-list">

          <div class="item item1" v-bind:class="[dropListAbout !== false ? activeClass: '', ``]">

            <div @click="openDropList1()" class="visible-part">

              <p class="header-text" v-html="currentProd.info.about.header"></p>

              <svg class="plus" width="22" height="22" viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0274 1.71683V10.0383H1.70665C1.17487 10.0383 0.75 10.4631 0.75 10.9949C0.75 11.522 1.17023 11.9617 1.70665 11.9617H10.0281V20.2832C10.0281 20.815 10.4631 21.25 10.9949 21.25C11.5313 21.25 11.9516 20.8103 11.9516 20.2832V11.9617H20.2724C20.8041 11.9617 21.2392 11.5267 21.2392 10.9949C21.2392 10.4585 20.7995 10.0383 20.2724 10.0383H11.9516V1.71683C11.9516 1.18972 11.5313 0.75 10.9949 0.75C10.463 0.75 10.0283 1.18503 10.0274 1.71643V1.71683Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>
              <svg class="minus" width="22" height="4" viewBox="0 0 22 4" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.75 1.99572C0.75 2.52282 1.17023 2.96255 1.70665 2.96255H10.2781H11.7016H20.2724C20.8041 2.96255 21.2392 2.5275 21.2392 1.99572C21.2392 1.45929 20.7995 1.03906 20.2724 1.03906H11.7016H10.2774H1.70665C1.17487 1.03906 0.75 1.46393 0.75 1.99572Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>

            </div>

            <div ref="productInfo" class="hide-part">
              <p class="reg-text" v-for="(key, value) in currentProd.info.about.text" v-html="key"></p>
            </div>

          </div>

          <div class="item item2" v-bind:class="[dropListCompound !== false ? activeClass: '', ``]">

            <div @click="openDropList2()" class="visible-part">

              <p class="header-text" v-html="currentProd.info.compound.header"></p>

              <svg class="plus" width="22" height="22" viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0274 1.71683V10.0383H1.70665C1.17487 10.0383 0.75 10.4631 0.75 10.9949C0.75 11.522 1.17023 11.9617 1.70665 11.9617H10.0281V20.2832C10.0281 20.815 10.4631 21.25 10.9949 21.25C11.5313 21.25 11.9516 20.8103 11.9516 20.2832V11.9617H20.2724C20.8041 11.9617 21.2392 11.5267 21.2392 10.9949C21.2392 10.4585 20.7995 10.0383 20.2724 10.0383H11.9516V1.71683C11.9516 1.18972 11.5313 0.75 10.9949 0.75C10.463 0.75 10.0283 1.18503 10.0274 1.71643V1.71683Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>
              <svg class="minus" width="22" height="4" viewBox="0 0 22 4" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.75 1.99572C0.75 2.52282 1.17023 2.96255 1.70665 2.96255H10.2781H11.7016H20.2724C20.8041 2.96255 21.2392 2.5275 21.2392 1.99572C21.2392 1.45929 20.7995 1.03906 20.2724 1.03906H11.7016H10.2774H1.70665C1.17487 1.03906 0.75 1.46393 0.75 1.99572Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>

            </div>

            <div class="hide-part">
              <p class="reg-text" v-for="(key, value) in currentProd.info.compound.text" v-html="key"></p>

              <div class="table-wrapper">
                <div class="left-col">
                  <p class="table-text"
                     v-for="(key, value) in currentProd.info.compound.table.leftCol" v-html="key">
                  </p>
                </div>
                <div class="right-col">
                  <p class="table-text"
                     v-for="(key, value) in currentProd.info.compound.table.rightCol"
                     v-html="key"></p>
                </div>
              </div>
            </div>

          </div>

          <div
              class="item item3"
              v-bind:class="[dropListPreparation !== false ? activeClass: '', ``]"
              v-if="currentProd.info.preparation"
          >
            <div @click="openDropList3()" class="visible-part">

              <p class="header-text" v-html="currentProd.info.preparation.header"></p>

              <svg class="plus" width="22" height="22" viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0274 1.71683V10.0383H1.70665C1.17487 10.0383 0.75 10.4631 0.75 10.9949C0.75 11.522 1.17023 11.9617 1.70665 11.9617H10.0281V20.2832C10.0281 20.815 10.4631 21.25 10.9949 21.25C11.5313 21.25 11.9516 20.8103 11.9516 20.2832V11.9617H20.2724C20.8041 11.9617 21.2392 11.5267 21.2392 10.9949C21.2392 10.4585 20.7995 10.0383 20.2724 10.0383H11.9516V1.71683C11.9516 1.18972 11.5313 0.75 10.9949 0.75C10.463 0.75 10.0283 1.18503 10.0274 1.71643V1.71683Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>
              <svg class="minus" width="22" height="4" viewBox="0 0 22 4" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.75 1.99572C0.75 2.52282 1.17023 2.96255 1.70665 2.96255H10.2781H11.7016H20.2724C20.8041 2.96255 21.2392 2.5275 21.2392 1.99572C21.2392 1.45929 20.7995 1.03906 20.2724 1.03906H11.7016H10.2774H1.70665C1.17487 1.03906 0.75 1.46393 0.75 1.99572Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>
            </div>

            <div class="hide-part">

              <div class="line" v-for="(key, value) in currentProd.info.preparation.text">
                <div class="icon" v-html="key.icon"></div>
                <p class="reg-text" v-html="key.text"></p>
              </div>

            </div>

          </div>

          <div
              class="item item6"
              v-bind:class="[dropListUsage !== false ? activeClass: '', ``]"
              v-if="currentProd.info.usage"
          >
            <div @click="openDropList6()" class="visible-part">

              <p class="header-text" v-html="currentProd.info.usage.header"></p>

              <svg class="plus" width="22" height="22" viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0274 1.71683V10.0383H1.70665C1.17487 10.0383 0.75 10.4631 0.75 10.9949C0.75 11.522 1.17023 11.9617 1.70665 11.9617H10.0281V20.2832C10.0281 20.815 10.4631 21.25 10.9949 21.25C11.5313 21.25 11.9516 20.8103 11.9516 20.2832V11.9617H20.2724C20.8041 11.9617 21.2392 11.5267 21.2392 10.9949C21.2392 10.4585 20.7995 10.0383 20.2724 10.0383H11.9516V1.71683C11.9516 1.18972 11.5313 0.75 10.9949 0.75C10.463 0.75 10.0283 1.18503 10.0274 1.71643V1.71683Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>
              <svg class="minus" width="22" height="4" viewBox="0 0 22 4" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.75 1.99572C0.75 2.52282 1.17023 2.96255 1.70665 2.96255H10.2781H11.7016H20.2724C20.8041 2.96255 21.2392 2.5275 21.2392 1.99572C21.2392 1.45929 20.7995 1.03906 20.2724 1.03906H11.7016H10.2774H1.70665C1.17487 1.03906 0.75 1.46393 0.75 1.99572Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>

            </div>

            <div class="hide-part">

              <p class="reg-text" v-html="key"
                 v-for="(key, value) in currentProd.info.usage.text"></p>

            </div>

          </div>

          <div v-if="false" class="item item4" v-bind:class="[dropListDoze !== false ? activeClass: '', ``]">

            <div @click="openDropList4()" class="visible-part">

              <p class="header-text" v-html="currentProd.info.doze.header"></p>

              <svg class="plus" width="22" height="22" viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0274 1.71683V10.0383H1.70665C1.17487 10.0383 0.75 10.4631 0.75 10.9949C0.75 11.522 1.17023 11.9617 1.70665 11.9617H10.0281V20.2832C10.0281 20.815 10.4631 21.25 10.9949 21.25C11.5313 21.25 11.9516 20.8103 11.9516 20.2832V11.9617H20.2724C20.8041 11.9617 21.2392 11.5267 21.2392 10.9949C21.2392 10.4585 20.7995 10.0383 20.2724 10.0383H11.9516V1.71683C11.9516 1.18972 11.5313 0.75 10.9949 0.75C10.463 0.75 10.0283 1.18503 10.0274 1.71643V1.71683Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>
              <svg class="minus" width="22" height="4" viewBox="0 0 22 4" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.75 1.99572C0.75 2.52282 1.17023 2.96255 1.70665 2.96255H10.2781H11.7016H20.2724C20.8041 2.96255 21.2392 2.5275 21.2392 1.99572C21.2392 1.45929 20.7995 1.03906 20.2724 1.03906H11.7016H10.2774H1.70665C1.17487 1.03906 0.75 1.46393 0.75 1.99572Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>

            </div>

            <div class="hide-part">

              <div class="table-wrapper">

                <table>

                  <tr class="header-table">
                    <th v-for="(key, value) in currentProd.info.doze.table.tableHeader"
                        v-html="key"></th>
                  </tr>

                  <tr class="tBody" v-for="(key, value) in currentProd.info.doze.table.tableBody">
                    <td v-for="secondKey in key" v-html="secondKey"></td>
                  </tr>

                </table>
              </div>

            </div>

          </div>

          <div
              class="item item5"
              v-bind:class="[
                { active: dropListInstruction !== false },
                { hide: numberProduction.toString() === 'fortini-15-powder' },
                { hide: numberProduction.toString() === 'nutrini-10' },
              ]"
              v-if="currentProd.info.instruction"
          >
            <div @click="openDropList5()" class="visible-part">

              <p class="header-text" v-html="currentProd.info.instruction.header"></p>

              <svg class="plus" width="22" height="22" viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0274 1.71683V10.0383H1.70665C1.17487 10.0383 0.75 10.4631 0.75 10.9949C0.75 11.522 1.17023 11.9617 1.70665 11.9617H10.0281V20.2832C10.0281 20.815 10.4631 21.25 10.9949 21.25C11.5313 21.25 11.9516 20.8103 11.9516 20.2832V11.9617H20.2724C20.8041 11.9617 21.2392 11.5267 21.2392 10.9949C21.2392 10.4585 20.7995 10.0383 20.2724 10.0383H11.9516V1.71683C11.9516 1.18972 11.5313 0.75 10.9949 0.75C10.463 0.75 10.0283 1.18503 10.0274 1.71643V1.71683Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>
              <svg class="minus" width="22" height="4" viewBox="0 0 22 4" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.75 1.99572C0.75 2.52282 1.17023 2.96255 1.70665 2.96255H10.2781H11.7016H20.2724C20.8041 2.96255 21.2392 2.5275 21.2392 1.99572C21.2392 1.45929 20.7995 1.03906 20.2724 1.03906H11.7016H10.2774H1.70665C1.17487 1.03906 0.75 1.46393 0.75 1.99572Z"
                    fill="#00ACE6" stroke="#00ACE6" stroke-width="0.5"/>
              </svg>

            </div>

            <div class="hide-part">

              <p class="reg-text" v-html="key"
                 v-for="(key, value) in currentProd.info.instruction.text"></p>

            </div>

          </div>

        </div>

      </div>

      <!--      <div class="more-production-wrapper">-->

      <!--        <p class="h2-title">Інші продукти:</p>-->

      <!--        <div class="more-production">-->

      <!--          <div class="item" v-for="(key, value) in moreInfo">-->
      <!--            <div class="description">-->
      <!--              <div class="left">-->
      <!--                <p class="name" v-html="key.name"></p>-->
      <!--                <p class="more-info" v-html="key.moreInfo"></p>-->
      <!--              </div>-->
      <!--              <div class="right">-->
      <!--                <img :src="require(`../assets/img/pack/${key.img[0]}`)" alt="">-->
      <!--              </div>-->
      <!--            </div>-->

      <!--            <div class="open-btn" @click="reloadPage(key.id)">-->
      <!--              <p>Детальніше</p>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--        </div>-->

      <!--      </div>-->
    </div>

  </div>
</template>

<script>
import axios from "axios"
import data from './../text-data/prod'

import SingleSelect from './../components/production-energy/filter-items/one-column/single-select';
import MultipleSelect from './../components/production-energy/filter-items/one-column/multiple-select';
import ColumnItem from './../components/production-energy/filter-items/column-item';

export default {
  name: 'prod',
  components: {
    data,
    'single-select': SingleSelect,
    'multiple-select': MultipleSelect,
    'column-item': ColumnItem
  },

  data: () => ({
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 15,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    currentOffset: 0,
    paginationFactor: 365,
    males: [
      'Хлопчик',
      'Дівчинка'
    ],
    selectedMale: '',

    activities: [
      'Лежачий хворий',
      'Сидячий хворий',
      'Нормальна активність дитини'
    ],
    selectedActivity: '',

    growthRates: [
      'Нормальне зростання',
      'Інтенсивне зростання'
    ],
    selectedGrowthRate: '',

    selectedAge: 0,
    selectedWeight: 0,

    states: [
      {
        name: 'Відсутній метаболічний стрес'
      },
      {
        name: 'Відсутній метаболічний стрес, але необхідно набрати вагу'
      },
      {
        name: 'Невеликий метаболічний стрес',
        points: [
          'Лежачий пацієнт із церебральним паралічем',
          'Анемія, лихоманка',
          'Легка інфекція',
          'Планова велика хірургічна операція'
        ]
      },
      {
        name: 'Середній метаболічний стрес',
        points: [
          'Перелом кісток',
          'Розширене хірургічне втручання',
          'Закрита черепно-мозкова травма',
          'Сепсис',
          'Виражена інфекція',
          'Опіки 10-30% поверхні тіла',
          'Пошкодження слизової оболонки після опромінення або хіміотерапії'
        ]
      },
      {
        name: 'Значний метаболічний стрес',
        points: [
          'Травма, інфекція',
          'Множинна травма',
          'Онкопатологія',
          'Опіки 30-50% поверхні тіла',
          'До корекції вродженої вади серця'
        ]
      },
      {
        name: 'Тяжкий метаболічний стрес',
        points: [
          'Опіки >50% поверхні тіла',
          'Муковісцидоз'
        ]
      }
    ],
    selectedStates: null,

    lockedValue: '',

    isCalculated: false,

    dayNormKkal: 0,
    dayNormMixture: 0,

    kkalK1: null,
    kkalK2: null,
    kkalK3: null,
    kkalK4: null,

    proteinK1: null,
    proteinK2: null,

    dayNormOfProteinGrams: 0,

    output: "",
    result: {
      dailyRate: "",
      oneFeeding: "",
      tablespoons: "",
      water: "",
      NumberOfFeedings: 0,
      valueOfSpoone: 0,
      spooneText: '',

    },
    interval: false,

    activeClass: "active",
    allProd: 10,

    dropList1: false,
    dropList2: false,
    dropList3: false,
    error: null,

    days: [
      {
        name: "1 день",
        type: 1,
      },
      {
        name: "2 дні",
        type: 2,
      },
      {
        name: "3 дні",
        type: 3,
      },
      {
        name: "4 дні",
        type: 4,
      },
      {
        name: "5 днів",
        type: 5,
      },
      {
        name: "6 днів",
        type: 6,
      },
      {
        name: "7 днів",
        type: 7,
      },
      {
        name: "8 днів",
        type: 8,
      },
      {
        name: "9 днів",
        type: 9,
      },
      {
        name: "10 днів",
        type: 10,
      },

    ],

    allProdItems: data,

    moreInfo: [],

    currentProd: null,

    dropListAbout: false,
    dropListCompound: false,
    dropListPreparation: false,
    dropListDoze: false,
    dropListInstruction: false,
    dropListUsage: false,

    filter2Chose: "Вік",
    filter3Chose: "Вкажіть кількість днів",

    weight: 0,

    showResult: false,

    filter2Type: "",
    filter3Type: "",

    numberProduction: '',


  }),

  mounted() {
    this.$nextTick(() => {
      if (sessionStorage.getItem("isDetaisOpen") === "open") {
        let element = this.$refs['productInfo'];
        this.openDropList1();

        let top = element.offsetTop;
        window.scrollTo(0, top + 60);
        sessionStorage.setItem("isDetaisOpen", "closed");
      }
    })

    this.paginationFactor = this.$refs.slider[0].getBoundingClientRect().width

  },

  beforeMount() {
    if (sessionStorage.getItem("production") === null) {
      this.numberProduction = this.$route.params.id;
      this.$router.push({name: "prod-energy", params: {id: this.numberProduction}}).catch(() => {
      });
    } else if (sessionStorage.getItem("production") !== null) {
      this.numberProduction = sessionStorage.getItem("production")
      this.$router.push({name: "prod-energy", params: {id: sessionStorage.getItem("production")}}).catch(() => {
      });
    } else {
      this.$router.push('/calculator').catch(() => {
      })
    }

    this.allProdItems.data.forEach((el) => {
      if (el.id === this.numberProduction.toString()) {
        this.currentProd = el;
      }
    });

    // if (this.$route.name === 'prod') {
    //   if (this.currentProd.filters.type.includes('hi')) {
    //     this.$router.push({name: "prod-energy", params: {id: this.currentProd.id}}).catch(() => {
    //     });
    //   }
    // } else {
    //   if (!this.currentProd.filters.type.includes('hi')) {
    //     this.$router.push({name: "prod", params: {id: this.currentProd.id}}).catch(() => {
    //     });
    //   }
    // }

    const productionShuffled = this.allProdItems.data.sort(() => 0.5 - Math.random());

    let production = productionShuffled.slice(0, 2);

    production.forEach((el) => {
      this.moreInfo.push(el)
    });
  },

  computed: {
    atEndOfList() {
      return this.currentOffset <= (this.paginationFactor * -1) * (this.currentProd.img.length - 400 / 350);
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
  },

  methods: {
    moveSlider(direction) {
      const slider = document.querySelector('.single-product__images');

      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
        slider.style.transform = `translateX(${this.currentOffset}px)`;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
        slider.style.transform = `translateX(${this.currentOffset}px)`;
      }
    },
    selectedMaleChange(value) {
      this.selectedMale = value;
    },
    selectedActivityChange(value) {
      this.selectedActivity = value;
    },
    selectedGrowthRateChange(value) {
      this.selectedGrowthRate = value
    },
    selectedStatesChange(value) {
      this.selectedStates = value.name;
    },
    selectedAgeChange(value) {
      this.selectedAge = value;
    },
    selectedWeightChange(value) {
      this.selectedWeight = value
    },

    calc() {
      this.calcKkalK1();
      this.calcKkalK2();
      this.calcKkalK3();
      this.calcKkalK4();


      this.dayNormKkal = Math.ceil(this.kkalK1 * this.kkalK2 * this.kkalK3 * this.kkalK4);
      if (this.currentProd.kkal) {
        this.dayNormMixture = Math.round((this.dayNormKkal * 100) / this.currentProd.kkal);
      } else {
        this.dayNormMixture = this.dayNormKkal;
      }

      this.calcProteinK1();
      this.calcProteinK2();

      this.dayNormOfProteinGrams = (this.selectedWeight * this.proteinK1 * this.proteinK2).toFixed(1);


      this.dayNormProteinMixture = Math.round(this.dayNormOfProteinGrams / this.currentProd.protein * 100);

      if (this.dayNormKkal && this.dayNormOfProteinGrams) {
        this.isCalculated = true;
      }
    },

    calcKkalK1() {
      let weight = Math.round(this.selectedWeight);
      if (this.selectedAge >= 0 && this.selectedAge <= 3) {
        if (this.selectedMale === 'Хлопчик') {
          switch (weight) {
            case 3 :
              this.kkalK1 = 148;
              break;
            case 4 :
              this.kkalK1 = 208;
              break;
            case 5 :
              this.kkalK1 = 267;
              break;
            case 6 :
              this.kkalK1 = 327;
              break;
            case 7 :
              this.kkalK1 = 386;
              break;
            case 8 :
              this.kkalK1 = 446;
              break;
            case 9 :
              this.kkalK1 = 505;
              break;
            case 10 :
              this.kkalK1 = 564;
              break;
            case 11 :
              this.kkalK1 = 624;
              break;
            case 12 :
              this.kkalK1 = 683;
              break;
            case 13 :
              this.kkalK1 = 743;
              break;
            case 14 :
              this.kkalK1 = 802;
              break;
            case 15 :
              this.kkalK1 = 862;
              break;
            case 16 :
              this.kkalK1 = 921;
              break;
            case 17 :
              this.kkalK1 = 981;
              break;
            case 18 :
              this.kkalK1 = 1040;
              break;
            case 19 :
              this.kkalK1 = 1100;
              break;
            case 20 :
              this.kkalK1 = 1159;
              break;
          }
        } else {
          switch (weight) {
            case 3 :
              this.kkalK1 = 144;
              break;
            case 4 :
              this.kkalK1 = 202;
              break;
            case 5 :
              this.kkalK1 = 260;
              break;
            case 6 :
              this.kkalK1 = 319;
              break;
            case 7 :
              this.kkalK1 = 377;
              break;
            case 8 :
              this.kkalK1 = 435;
              break;
            case 9 :
              this.kkalK1 = 494;
              break;
            case 10 :
              this.kkalK1 = 552;
              break;
            case 11 :
              this.kkalK1 = 610;
              break;
            case 12 :
              this.kkalK1 = 668;
              break;
            case 13 :
              this.kkalK1 = 727;
              break;
            case 14 :
              this.kkalK1 = 785;
              break;
            case 15 :
              this.kkalK1 = 843;
              break;
            case 16 :
              this.kkalK1 = 902;
              break;
            case 17 :
              this.kkalK1 = 960;
              break;
            case 18 :
              this.kkalK1 = 1018;
              break;
            case 19 :
              this.kkalK1 = 1076;
              break;
            case 20 :
              this.kkalK1 = 1135;
              break;
          }
        }
      } else if (this.selectedAge > 3 && this.selectedAge <= 10) {
        if (this.selectedMale === 'Хлопчик') {
          switch (weight) {
            case 10:
            case 11:
              this.kkalK1 = 732;
              break;
            case 12:
            case 13:
              this.kkalK1 = 777;
              break;
            case 14:
            case 15:
              this.kkalK1 = 823;
              break;
            case 16:
            case 17:
              this.kkalK1 = 868;
              break;
            case 18:
            case 19:
              this.kkalK1 = 914;
              break;
            case 20:
            case 21:
              this.kkalK1 = 959;
              break;
            case 22:
            case 23:
              this.kkalK1 = 1004;
              break;
            case 24:
            case 25:
              this.kkalK1 = 1050;
              break;
            case 26:
            case 27:
              this.kkalK1 = 1095;
              break;
            case 28:
            case 29:
              this.kkalK1 = 1141;
              break;
            case 30:
            case 31:
              this.kkalK1 = 1186;
              break;
            case 32:
            case 33:
              this.kkalK1 = 1231;
              break;
            case 34:
            case 35:
              this.kkalK1 = 1277;
              break;
            case 36:
            case 37:
              this.kkalK1 = 1322;
              break;
            case 38:
            case 39:
              this.kkalK1 = 1368;
              break;
            case 40:
            case 41:
              this.kkalK1 = 1413;
              break;
            case 42:
            case 43:
              this.kkalK1 = 1458;
              break;
            case 44:
            case 45:
              this.kkalK1 = 1504;
              break;
          }
        } else {
          switch (weight) {
            case 10:
            case 11:
              this.kkalK1 = 689;
              break;
            case 12:
            case 13:
              this.kkalK1 = 730;
              break;
            case 14:
            case 15:
              this.kkalK1 = 770;
              break;
            case 16:
            case 17:
              this.kkalK1 = 811;
              break;
            case 18:
            case 19:
              this.kkalK1 = 851;
              break;
            case 20:
            case 21:
              this.kkalK1 = 892;
              break;
            case 22:
            case 23:
              this.kkalK1 = 933;
              break;
            case 24:
            case 25:
              this.kkalK1 = 973;
              break;
            case 26:
            case 27:
              this.kkalK1 = 1014;
              break;
            case 28:
            case 29:
              this.kkalK1 = 1054;
              break;
            case 30:
            case 31:
              this.kkalK1 = 1095;
              break;
            case 32:
            case 33:
              this.kkalK1 = 1136;
              break;
            case 34:
            case 35:
              this.kkalK1 = 1176;
              break;
            case 36:
            case 37:
              this.kkalK1 = 1217;
              break;
            case 38:
            case 39:
              this.kkalK1 = 1257;
              break;
            case 40:
            case 41:
              this.kkalK1 = 1298;
              break;
            case 42:
            case 43:
              this.kkalK1 = 1339;
              break;
            case 44:
            case 45:
              this.kkalK1 = 1379;
              break;
          }
        }
      } else {
        if (this.selectedMale === 'Хлопчик') {
          if (weight >= 10 && weight < 12)
            this.kkalK1 = 827;
          else if (weight >= 12 && weight < 14)
            this.kkalK1 = 854;
          else if (weight === 14)
            this.kkalK1 = 881;
          else if (weight >= 15 && weight < 20)
            this.kkalK1 = 894;
          else if (weight >= 20 && weight < 25)
            this.kkalK1 = 961;
          else if (weight >= 25 && weight < 30)
            this.kkalK1 = 1028;
          else if (weight >= 30 && weight < 35)
            this.kkalK1 = 1095;
          else if (weight >= 35 && weight < 40)
            this.kkalK1 = 1162;
          else if (weight >= 40 && weight < 45)
            this.kkalK1 = 1229;
          else if (weight >= 45 && weight < 50)
            this.kkalK1 = 1296;
          else if (weight >= 50 && weight < 55)
            this.kkalK1 = 1363;
          else if (weight >= 55 && weight < 60)
            this.kkalK1 = 1430;
          else if (weight >= 60 && weight < 65)
            this.kkalK1 = 1497;
          else if (weight >= 65 && weight < 70)
            this.kkalK1 = 1564;
          else if (weight >= 70 && weight < 75)
            this.kkalK1 = 1631;
          else if (weight >= 75 && weight < 80)
            this.kkalK1 = 1698;
          else if (weight >= 80 && weight < 86)
            this.kkalK1 = 1765;
          else
            this.kkalK1 = 1832;
        } else {
          if (weight >= 10 && weight < 12)
            this.kkalK1 = 836;
          else if (weight >= 12 && weight < 14)
            this.kkalK1 = 871;
          else if (weight === 14)
            this.kkalK1 = 907;
          else if (weight >= 15 && weight < 20)
            this.kkalK1 = 925;
          else if (weight >= 20 && weight < 25)
            this.kkalK1 = 1013;
          else if (weight >= 25 && weight < 30)
            this.kkalK1 = 1102;
          else if (weight >= 30 && weight < 35)
            this.kkalK1 = 1190;
          else if (weight >= 35 && weight < 40)
            this.kkalK1 = 1279;
          else if (weight >= 40 && weight < 45)
            this.kkalK1 = 1367;
          else if (weight >= 45 && weight < 50)
            this.kkalK1 = 1456;
          else if (weight >= 50 && weight < 55)
            this.kkalK1 = 1544;
          else if (weight >= 55 && weight < 60)
            this.kkalK1 = 1633;
          else if (weight >= 60 && weight < 65)
            this.kkalK1 = 1721;
          else if (weight >= 65 && weight < 70)
            this.kkalK1 = 1810;
          else if (weight >= 70 && weight < 75)
            this.kkalK1 = 1898;
          else if (weight >= 75 && weight < 80)
            this.kkalK1 = 1987;
          else if (weight >= 80 && weight < 86)
            this.kkalK1 = 2075;
          else
            this.kkalK1 = 2164;
        }
      }
    },
    calcKkalK2() {
      switch (this.selectedStates) {
        case 'Відсутній метаболічний стрес':
          this.kkalK2 = 1;
          break;
        case 'Відсутній метаболічний стрес, але необхідно набрати вагу':
          this.kkalK2 = 1.3;
          break;
        case 'Невеликий метаболічний стрес':
          this.kkalK2 = 1.1;
          break;
        case 'Середній метаболічний стрес':
          this.kkalK2 = 1.3;
          break;
        case 'Значний метаболічний стрес':
          this.kkalK2 = 1.55;
          break;
        case 'Тяжкий метаболічний стрес':
          this.kkalK2 = 1.85;
          break;
      }
    },
    calcKkalK3() {
      if (this.selectedGrowthRate === 'Нормальне зростання' || !this.selectedGrowthRate)
        this.kkalK3 = 1;
      else
        this.kkalK3 = 1.2;
    },
    calcKkalK4() {
      if (this.selectedActivity === 'Лежачий хворий')
        this.kkalK4 = 1;
      else if (this.selectedActivity === 'Сидячий хворий')
        this.kkalK4 = 1.2;
      else
        this.kkalK4 = 1.4;
    },

    calcProteinK1() {
      if (this.selectedMale === 'Хлопчик') {
        if (this.selectedAge < 1)
          this.proteinK1 = 1.12;
        else if (this.selectedAge === 1)
          this.proteinK1 = 0.95;
        else if (this.selectedAge === 1.5)
          this.proteinK1 = 0.85;
        else if (this.selectedAge >= 2 && this.selectedAge < 3)
          this.proteinK1 = 0.79;
        else if (this.selectedAge >= 3 && this.selectedAge < 4)
          this.proteinK1 = 0.73;
        else if (this.selectedAge >= 4 && this.selectedAge < 6)
          this.proteinK1 = 0.69;
        else if (this.selectedAge >= 6 && this.selectedAge < 7)
          this.proteinK1 = 0.72;
        else if (this.selectedAge >= 7 && this.selectedAge < 8)
          this.proteinK1 = 0.74;
        else if (this.selectedAge >= 8 && this.selectedAge < 11)
          this.proteinK1 = 0.75;
        else if (this.selectedAge >= 11 && this.selectedAge < 12)
          this.proteinK1 = 0.75;
        else if (this.selectedAge >= 12 && this.selectedAge < 13)
          this.proteinK1 = 0.74;
        else if (this.selectedAge >= 13 && this.selectedAge < 14)
          this.proteinK1 = 0.73;
        else if (this.selectedAge >= 14 && this.selectedAge < 16)
          this.proteinK1 = 0.72;
        else if (this.selectedAge >= 16 && this.selectedAge < 17)
          this.proteinK1 = 0.71;
        else if (this.selectedAge >= 17 && this.selectedAge < 18)
          this.proteinK1 = 0.70;
        else
          this.proteinK1 = 0.69;

      } else {
        if (this.selectedAge < 1)
          this.proteinK1 = 1.12;
        else if (this.selectedAge === 1)
          this.proteinK1 = 0.95;
        else if (this.selectedAge === 1.5)
          this.proteinK1 = 0.85;
        else if (this.selectedAge >= 2 && this.selectedAge < 3)
          this.proteinK1 = 0.79;
        else if (this.selectedAge >= 3 && this.selectedAge < 4)
          this.proteinK1 = 0.73;
        else if (this.selectedAge >= 4 && this.selectedAge < 6)
          this.proteinK1 = 0.69;
        else if (this.selectedAge >= 6 && this.selectedAge < 7)
          this.proteinK1 = 0.72;
        else if (this.selectedAge >= 7 && this.selectedAge < 8)
          this.proteinK1 = 0.74;
        else if (this.selectedAge >= 8 && this.selectedAge < 11)
          this.proteinK1 = 0.75;
        else if (this.selectedAge >= 11 && this.selectedAge < 12)
          this.proteinK1 = 0.73;
        else if (this.selectedAge >= 12 && this.selectedAge < 13)
          this.proteinK1 = 0.72;
        else if (this.selectedAge >= 13 && this.selectedAge < 14)
          this.proteinK1 = 0.71;
        else if (this.selectedAge >= 14 && this.selectedAge < 15)
          this.proteinK1 = 0.70;
        else if (this.selectedAge >= 15 && this.selectedAge < 16)
          this.proteinK1 = 0.69;
        else if (this.selectedAge >= 16 && this.selectedAge < 17)
          this.proteinK1 = 0.68;
        else if (this.selectedAge >= 17 && this.selectedAge < 18)
          this.proteinK1 = 0.67;
        else
          this.proteinK1 = 0.66;
      }
    },
    calcProteinK2() {
      switch (this.selectedStates) {
        case 'Відсутній метаболічний стрес':
          this.proteinK2 = 1;
          break;
        case 'Відсутній метаболічний стрес, але необхідно набрати вагу':
          this.proteinK2 = 2.5;
          break;
        case 'Невеликий метаболічний стрес':
          this.proteinK2 = 1.65;
          break;
        case 'Середній метаболічний стрес':
          this.proteinK2 = 2.25;
          break;
        case 'Значний метаболічний стрес':
          this.proteinK2 = 2.75;
          break;
        case 'Тяжкий метаболічний стрес':
          this.proteinK2 = 3.5;
          break;
      }
    },

    getSpoonCount(val) {
      return this.roundToMultiple(val, 20) / 20;
    },
    getWaterCount(val) {
      return this.roundToMultiple(val, 20) - (this.getSpoonCount(val) * 3);
    },
    getWaterCountGipo(val) {
      return this.roundToMultiple(val, 20) - (this.getSpoonCount(val * 1.5) * 3);
    },
    getGipoMl(val) {
      return Math.round(val / (this.currentProd.protein * 1.5) * 100);
    },

    roundToMultiple(num, multiple) {
      return Math.ceil(num / multiple) * multiple;
    },
    replaceDotToComa(val) {
      return val.replace('.', ',');
    },

    openDropList1() {
      if (this.dropListAbout !== true) {
        this.dropListAbout = true;
      } else {
        this.dropListAbout = false;
      }

    },

    openDropList2() {
      if (this.dropListCompound !== true) {
        this.dropListCompound = true;
      } else {
        this.dropListCompound = false;

      }

    },

    openDropList3() {
      if (this.dropListPreparation !== true) {
        this.dropListPreparation = true;
      } else {
        this.dropListPreparation = false;
      }
    },
    openDropList6() {
      if (this.dropListUsage !== true) {
        this.dropListUsage = true;
      } else {
        this.dropListUsage = false;
      }
    },

    openDropList4() {
      if (this.dropListDoze !== true) {
        this.dropListDoze = true;
      } else {
        this.dropListDoze = false;

      }

    },

    openDropList5() {
      if (this.dropListInstruction !== true) {
        this.dropListInstruction = true;
      } else {
        this.dropListInstruction = false;
      }

    },

    openDropListAge() {
      if (this.dropList2 === false && this.dropList3 !== true) {

        this.dropList2 = true

      } else if (this.dropList2 === false && this.dropList3 === true) {

        setTimeout(() => {
          this.dropList2 = true
        }, 500);

        this.dropList3 = false

      } else {

        this.dropList2 = false
      }
    },

    openDropListAge2() {

      if (this.dropList3 === false && this.dropList2 !== true) {
        this.dropList3 = true
      } else {
        this.dropList3 = false
      }
    },

    choseFilter2(value, name) {

      this.filter2Chose = name;
      this.dropList2 = false;
      this.filter2Type = value;

    },

    choseFilter3(value, name) {

      this.filter3Chose = name;
      this.dropList3 = false;
      this.filter3Type = value;

    },

    async saveImg() {
      const el = this.$refs.printMe;

      let options = {
        allowTaint: true,
        type: "dataURL"
      };
      this.output = await this.$html2canvas(el, options);

      this.saveAs(this.output, this.currentProd.name.replace(/ /g, '') + '.png');
    },

    async sendToServerScreen() {

      const el = this.$refs.printMe;

      let options = {
        allowTaint: true,
        type: "dataURL"
      };
      this.output = await this.$html2canvas(el, options);


      let myFormData = new FormData();
      let blob = this.dataURItoBlob(this.output);
      myFormData.append("picture", blob);


      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.post(this.$root.globalUrl + "api/v1/calculator/save_calc_result", myFormData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      }).then(res => {
        let img = res.data.data.hash;
        document
            .getElementById("viber_share")
            .setAttribute(
                "href",
                "https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=" +
                encodeURIComponent(
                    "viber://forward?text=" +
                    encodeURIComponent(this.$root.globalUrl + "" + "uploads/calculator/" + img)
                )
            );
        document.getElementById("viber_share").click();
      })

    },

    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var _ia = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
      }

      var dataView = new DataView(arrayBuffer);
      var blob = new Blob([dataView], {type: mimeString});
      return blob;
    },

    saveAs(uri, filename) {
      let link = document.createElement('a');

      if (typeof link.download === 'string') {

        link.href = uri;
        link.download = filename;

        //Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.click();

        //remove the link when done
        document.body.removeChild(link);

      } else {

        window.open(uri);

      }
    },

    reloadPage(id) {
      window.scrollTo(0, 0);
      sessionStorage.setItem("production", id);
      // this.$router.go("/calc/prod")
      this.$router.push({name: 'prod-energy', params: {id: id}});
      this.$router.go();
    },

    declOfNum(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },
  }
}
</script>

<style lang="scss">
@import "../style/vars";

.production {
  position: relative;

  * {
    user-select: none;
  }

  .head-links {
    background-color: rgba(255, 255, 255, 0.49);
    width: 100%;
    padding: 10px 15px;

    .back-links {
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    span {
      padding: 0 5px;
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    .now-page {
      color: #00ACE6;
      font-family: $fontSB;
      font-size: 11px;
    }
  }

  .content-wrapper {
    padding: 0 15px;
    margin-bottom: 30px;


    .content {
      border-radius: 4px;
      background: white;

      display: flex;
      flex-direction: column;
      align-items: center;

      .production-block {
        background: #D5EAF8;
        border-radius: 4px;
        width: 100%;

        .single-product {
          &__slider-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
          }


          &__nav {
            position: absolute;
            z-index: 1;
            top: 50%;
            width: 8px;
            height: 8px;
            border-top: 2px solid #4a2a80;
            border-right: 2px solid #4a2a80;
            cursor: pointer;

            &:after {
              display: none;
            }

            &.disabled {
              opacity: .3;
            }

            &.swiper-button-prev {
              left: 10px;
              transform: translateY(-50%) rotate(-135deg);
            }

            &.swiper-button-next {
              right: 10px;
              transform: translateY(-50%) rotate(45deg);
            }
          }

          &__slider {
            overflow: hidden;
            max-width: 100%;
            width: 100%;
          }

          &__images {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            transition: .6s;
          }

          &__image {
            margin-bottom: 8px;
            min-width: 100%;

            img {
              max-height: 220px;
              object-fit: contain;
            }
          }
        }

        .calc-day-norm {
          padding: 26px 15px 17px 15px;

          &.active {
            display: none;
          }

          button {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            text-transform: uppercase;
            color: #FFFFFF;
            background: #00ACE6;

            border: none;
            outline: none;

            border-radius: 4px;

            font-size: 14px;
            line-height: 16px;
            font-family: $fontB;
            cursor: pointer;
          }

          button:disabled {
            cursor: not-allowed;
            background: #EFF3F8;
            color: #9AB9D8;
          }

          .sampling-btn {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            color: #00ACE6;
            border: 1px solid #00ACE6;
            background-color: transparent;
            border-radius: 4px;
            cursor: pointer;

            p {
              letter-spacing: 0.05em;
              text-transform: uppercase;

              color: #00ACE6;
              font-size: 14px;
              font-family: $fontB;
            }
          }
        }

        .day-norm {
          display: flex;
          flex-direction: column;

          padding: 8px 15px 30px 15px;

          background: #D5EAF8;

          .kkal-norm {
            display: flex;
            flex-direction: column;

            color: #1E3B89;

            .title {
              font-family: $fontSB;
              font-size: 14px;
              line-height: 122.56%;


              padding-bottom: 12px;
            }

            .kkal-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;

              font-size: 15px;
              line-height: 122.56%;

              padding-bottom: 24px;

              .item {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                padding-left: 5%;
                width: 44%;

                .icon {
                  padding-right: 10px;
                }

                .value {
                  .number {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 105%;
                  }
                }
              }
            }
          }

          .kkal-norm:last-child {
            .kkal-container {
              padding-bottom: 0;
            }
          }
        }

        .day-norm-powder {
          padding: 8px 0px 30px 0px;
          background: #D5EAF8;

          .block {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            color: #1E3B89;

            border-bottom: 1px solid rgba(30, 59, 137, 0.25);

            padding: 0 16px 22px;

            .item {
              width: 45%;

              .title {
                font-family: $fontSB;
                font-size: 14px;
                line-height: 122.56%;

                padding-bottom: 12px;


              }
            }


            .item-container {
              display: flex;

              font-size: 15px;
              line-height: 122.56%;

              padding-left: 10px;

              .icon {
                padding-right: 10px;
              }

              .value {
                .number {
                  font-weight: bold;
                  font-size: 20px;
                  line-height: 105%;
                }
              }
            }
          }

          .block:last-child {
            border: none;
            padding-bottom: 0;
          }

          .col {
            display: flex;
            flex-direction: column;

            padding-top: 14px;


            .title {
              font-family: $fontSB;

              b {
                font-family: $fontB;
                font-weight: normal;
              }
            }


            .items {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              flex-wrap: wrap;


              .item {
                padding-top: 25px;
                width: 45%;

              }

              .water {
                padding-top: 5px;
                padding-left: 10px;

                font-size: 15px;
                line-height: 18px;
              }
            }
          }
        }

        .production-item {
          padding: 15px;
          border-bottom: 1px solid rgba(30, 59, 137, 0.25);

          .name {
            font-family: $fontSB;
            font-size: 15px;
            color: #1E3B89;
          }

          .type {
            color: #1E3B89;
            font-size: 12px;
            font-family: $fontL;

          }

          img {
            display: block;
            max-width: 140px;
            width: 100%;
            height: auto;
            margin: 10px auto;
          }
        }

        .drop-item1 {
          border-bottom: 1px solid rgba(30, 59, 137, 0.25);
          padding: 15px;
          position: relative;
          display: flex;
          justify-content: center;
          flex-direction: column;
          transition: background-color .5s ease;

          .visible-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
              display: flex;
              font-size: 15px;
              font-family: $fontR;
              color: #1E3B89;
            }

            .icon {
              display: flex;
              margin-right: 20px;
            }

            .arrow {
              transition: transform .2s ease;
              display: flex;
              margin-left: auto;
              cursor: pointer;
            }
          }

          .hide-item {
            padding: 0 15px;
            z-index: 10;
            position: absolute;
            top: 57px;
            left: 0;
            width: 100%;
            max-height: 0;
            background: #D5EAF8;
            overflow: hidden;
            transition: padding .5s ease, max-height .5s ease;


            p {
              padding: 10px 0;
              color: #1E3B89;
              font-size: 15px;
              font-family: $fontL;

              &.active, &:hover {
                font-family: $fontR;
              }
            }
          }

          &.active {

            .hide-item {
              border-top: 1px solid #DAE3ED;
              max-height: 500px;
              padding: 10px 15px;
            }

            .arrow {
              transform: rotate(180deg);
            }
          }
        }

        .drop-item2 {
          border-bottom: 1px solid rgba(30, 59, 137, 0.25);
          padding: 15px;
          position: relative;
          display: flex;
          justify-content: center;
          flex-direction: column;
          transition: background-color .5s ease;

          .visible-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
              display: flex;
              font-size: 15px;
              font-family: $fontR;
              color: #1E3B89;
            }

            .icon {
              display: flex;
              margin-right: 20px;
            }

            .arrow {
              transition: transform .2s ease;
              display: flex;
              margin-left: auto;
            }
          }

          .hide-item {
            padding: 0 15px;
            z-index: 10;
            position: absolute;
            top: 57px;
            left: 0;
            width: 100%;
            max-height: 0;
            background: #D5EAF8;
            overflow: hidden;
            transition: padding .5s ease, max-height .5s ease;


            p {
              padding: 10px 0;
              color: #1E3B89;
              font-size: 15px;
              font-family: $fontL;

              &.active, &:hover {
                font-family: $fontR;
              }
            }
          }

          &.active {

            .hide-item {
              border-top: 1px solid #DAE3ED;
              max-height: 500px;
              padding: 10px 15px;
            }

            .arrow {
              transform: rotate(180deg);
            }
          }
        }

        .weight-block {
          display: flex;
          padding: 0 15px;

          .left {
            border-right: 1px solid rgba(30, 59, 137, 0.25);
            padding: 8px 0;
            width: 45%;
            margin: 10px 0;
            display: flex;
            align-items: center;

            svg {
              margin-right: 20px;
            }
          }

          .right {
            width: 55%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;

            .minus {
              height: 25px;
              display: flex;
              align-items: center;
            }
          }
        }

        .calc-btn-wrapper {
          padding: 20px 15px 15px 15px;
          border-top: 1px solid rgba(30, 59, 137, 0.25);

          .calc-btn {
            width: 100%;
            height: 40px;
            background: #00ACE6;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;


            p {
              font-size: 14px;
              letter-spacing: 0.05em;
              font-family: $fontB;
              text-transform: uppercase;
              color: white;
            }
          }
        }

        .result-wrapper {
          max-height: 0;
          overflow: hidden;
          transition: padding .5s ease, max-height .5s ease;

          &.active {
            max-height: 1000px;
            padding: 0 0 0 0;
          }

          .result-img {
            background-color: #D5EAF8;
            padding: 30px 0 20px 0;
          }

          .line {
            padding: 0 15px;
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }

            .icon-wrapper {
              min-width: 35px;
              margin-right: 20px;
            }

            p {
              font-family: $fontR;
              font-size: 14px;
              color: #1E3B89;

              span {
                font-size: 15px;
                font-family: $fontB;

                span {
                  font-size: 20px;
                }
              }
            }
          }

          .save-btn {
            background: #1E3B89;
            border-radius: 4px;
            padding: 15px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            .btns {
              width: 96px;
              display: flex;
              flex-direction: column;
              align-items: center;

              p {
                margin-top: 10px;
                text-align: center;
                font-size: 12px;
                color: white;
                font-family: $fontR;
              }
            }

          }
        }
      }

      .drop-list {

        .item1 {
          padding: 0 15px;
          border-bottom: 1px solid rgba(30, 59, 137, 0.25);


          .visible-part {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-text {
              font-size: 15px;
              color: #1E3B89;
              font-family: $fontR;
            }
          }

          .hide-part {
            padding: 0;

            .reg-text {
              font-size: 14px;
              font-family: $fontL;
              color: #1E3B89;
              margin-bottom: 10px;

              span {
                font-family: $fontSB;
              }
            }
          }

        }

        .item2 {
          border-bottom: 1px solid rgba(30, 59, 137, 0.25);

          .visible-part {
            padding: 0 15px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-text {
              font-size: 15px;
              color: #1E3B89;
              font-family: $fontR;
            }
          }

          .hide-part {
            padding: 0;

            .reg-text {
              padding: 0 15px;

              font-size: 14px;
              font-family: $fontL;
              color: #1E3B89;
              margin-bottom: 10px;

              span {
                font-family: $fontSB;
              }
            }

            .table-wrapper {
              margin-top: 30px;

              display: flex;
              flex-direction: row;


              .left-col {

                width: 60%;

                p {
                  padding: 0 0 0 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: auto 0;
                  height: 60px;
                  text-align: center;
                  font-family: $fontL;
                  font-size: 13px;
                  color: #1E3B89;

                  b {
                    font-family: $fontL;
                    font-weight: inherit;
                  }

                  &:nth-child(2n) {
                    background: #CBDFF2;
                  }

                  &:nth-child(2n+1) {
                    background: #E0ECF7;
                  }

                  &:nth-child(1) {
                    border-radius: 5px 0 0 0;
                    color: white;
                    font-family: $fontR;
                    background: #00ACE6;
                  }


                  span {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: $fontSB;
                    text-align: left;
                    margin-right: auto;
                  }
                }
              }

              .right-col {
                width: 40%;

                p {
                  padding: 0 15px 0 0;

                  justify-content: center;
                  display: flex;
                  align-items: center;
                  height: 60px;
                  text-align: center;
                  font-family: $fontL;
                  font-size: 13px;
                  color: #1E3B89;

                  &:nth-child(2n) {
                    background: #CBDFF2;
                  }

                  &:nth-child(2n+1) {
                    background: #E0ECF7;
                  }

                  &:nth-child(1) {
                    border-left: 1px solid white;
                    font-family: $fontR;
                    border-radius: 0 5px 0 0;
                    color: white;
                    background: #00ACE6;
                  }

                  span {

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: $fontB;
                    text-align: left;
                  }
                }
              }
            }


          }

        }

        .item4 {
          border-bottom: 1px solid rgba(30, 59, 137, 0.25);

          .visible-part {
            padding: 0 15px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-text {
              font-size: 15px;
              color: #1E3B89;
              font-family: $fontR;
            }
          }

          .hide-part {
            .table-wrapper {
              -webkit-overflow-scrolling: auto;
              overflow-x: auto;
            }

            table {
              min-width: 600px;
              border-collapse: collapse;

              * {
                border-top: 0;
                border-left: 0;
                border-bottom: 0;
                border-right: 0;

              }

              .header-table {
                background: #00ACE6;
                border: none;

                th {
                  &:first-child {
                    border-radius: 5px 0 0 0;
                  }

                  &:last-child {
                    border-radius: 0 5px 0 0;
                  }

                  color: white;
                  padding: 5px 10px;
                  text-align: center;
                  vertical-align: center;
                  font-size: 14px;
                  font-family: $fontSB;
                }
              }

              .tBody {
                &:nth-child(2n +1) {
                  background: #E0ECF7;
                }

                &:nth-child(2n) {
                  background: #CBDFF2;
                }

                td {
                  width: 20%;
                  text-align: center;
                  vertical-align: center;
                  padding: 10px 10px;
                  font-family: $fontL;
                  font-size: 14px;
                  color: #1E3B89;


                }
              }
            }
          }
        }

        .item3 {
          border-bottom: 1px solid rgba(30, 59, 137, 0.25);

          .visible-part {
            padding: 0 15px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-text {
              font-size: 15px;
              color: #1E3B89;
              font-family: $fontR;
            }

            svg {
              cursor: pointer;
            }
          }

          .hide-part {
            padding: 0;

            .line {
              padding: 0 15px;
              margin-bottom: 10px;
              display: flex;
              flex-direction: row;

              .icon {
                display: flex;
                justify-content: center;
                min-width: 60px;
                margin-right: 10px;
              }

              .reg-text {
                padding: 0 15px;

                font-size: 14px;
                font-family: $fontL;
                color: #1E3B89;
                margin-bottom: 10px;

                span {
                  font-family: $fontSB;
                }
              }
            }

          }

        }

        .item5 {

          .visible-part {
            padding: 0 15px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-text {
              font-size: 15px;
              color: #1E3B89;
              font-family: $fontR;
            }
          }

          .hide-part {
            padding: 0;

            .reg-text {
              padding: 0 15px;

              font-size: 14px;
              font-family: $fontL;
              color: #1E3B89;
              margin-bottom: 10px;

              a {
                display: flex;
                align-items: center;
              }

              svg {
                min-width: 26px;
                width: 26px;
                height: auto;
                display: block;
                margin-right: 10px;
              }

              span {
                font-family: $fontSB;
              }
            }

          }
        }

        .item6 {
          border-bottom: 1px solid rgba(30, 59, 137, 0.25);

          .visible-part {
            padding: 0 15px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-text {
              font-size: 15px;
              color: #1E3B89;
              font-family: $fontR;
            }
          }

          .hide-part {
            padding: 0;

            .reg-text {
              padding: 0 15px;

              font-size: 14px;
              font-family: $fontL;
              color: #1E3B89;
              margin-bottom: 10px;

              span {
                font-family: $fontSB;
              }
            }

          }
        }

        .item {

          &:last-child {
            border-bottom: none !important;
          }

          .hide-part {
            transition: padding-top 500ms ease, padding-bottom 500ms ease, max-height .2s ease;
            overflow: hidden;
            max-height: 0;
          }

          .plus {
            display: block;
          }

          .minus {
            display: none;
          }

          &.active {
            .plus {
              display: none;
            }

            .minus {
              display: block;
            }

            .hide-part {
              max-height: 20000px;
            }
          }

          &.item1 {
            &.active {
              .hide-part {
                padding: 15px 0;
              }
            }
          }

          &.item2 {
            &.active {
              .hide-part {
                padding: 15px 0 0 0;
              }

            }
          }

          &.item3 {
            &.active {
              .hide-part {
                padding: 15px 0;
              }

            }
          }

          &.item4 {
            &.active {
              .hide-part {
                padding: 15px 0 0 0;
              }
            }
          }

          &.item5 {
            &.active {
              .hide-part {
                padding: 15px 0;
              }
            }
          }
        }

      }

    }

    .visible-part {
      svg {
        cursor: pointer;
      }
    }

    .more-production-wrapper {
      margin-top: 30px;

      .h2-title {
        margin-bottom: 12px;
        font-family: $fontSB;
        font-size: 16px;
        color: #9AB9D8;
        text-align: center;
        letter-spacing: 1px;
      }

      .more-production {

        .item {
          margin-bottom: 20px;
          background: #FFFFFF;
          border-radius: 4px;
          padding: 15px;

          .description {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .left {
              max-width: 140px;

              .name {
                font-size: 15px;
                line-height: 18px;

                color: #1E3B89;
                font-family: $fontSB;
              }

              .more-info {
                margin-top: 5px;
                font-family: $fontL;
                font-size: 12px;
                color: rgba(30, 59, 137, 0.7);
              }
            }

            .right {
              max-width: 115px;
              width: 100%;
              cursor: pointer;

              img {
                display: block;
                width: 100%;
                max-height: 220px;
                object-fit: contain;
                height: auto;
              }
            }
          }

          .open-btn {
            margin: 10px auto 0 auto;
            border: 1px solid #00ACE6;
            box-sizing: border-box;
            border-radius: 4px;
            max-width: 200px;
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            p {
              color: #00ACE6;
              font-family: $fontB;
              font-size: 14px;
              text-transform: uppercase;
              letter-spacing: 0.05em;
            }
          }
        }

      }
    }
  }

  .filter-items {
    &.active {
      display: none;
    }
  }

  .share-btn {
    padding: 26px 15px;

    display: flex;
    justify-content: space-between;

    width: 100%;

    cursor: pointer;

    .btn {
      max-width: 50%;
      width: 100%;

      border: 1px solid #00ACE6;
      box-sizing: border-box;
      border-radius: 4px;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 10px;

      font-family: $fontB;
      font-size: 12px;
      text-transform: uppercase;
      color: #00ACE6;

      svg {
        margin-right: 10px;
      }

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
  }
}

</style>
